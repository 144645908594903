<!-- frontend/src/components/Footer.vue -->
<template>
  <footer class="footer">
    <div >
      &copy; {{ currentYear }} ChartStudio. All rights reserved.
      <!---
      <router-link to="/privacy" class="footer-link">Privacy Policy</router-link>
      <router-link to="/terms" class="footer-link">Terms of Service</router-link>
      --->
    </div>
    <div class="footer-links">
      <a href="https://www.reddit.com/r/macapps/comments/1ekgumg/chartstudio_visualize_your_data_easily/" target="_blank">Reddit</a>
      <a href="https://zhuanlan.zhihu.com/p/712826861" target="_blank">ZhiHu</a>
      <a href="https://www.producthunt.com/products/chartstudio" target="_blank">ProductHunt</a>
      <a href="https://www.tiktok.com/@emperinter/video/7314043609639095554?q=chartstudio&t=1728060728764" target="_blank">TikTok</a>
      <a href="https://youtu.be/SJ6OHKx4C8U" target="_blank">Youtube</a>     
      <a href="https://news.ycombinator.com/item?id=41759039" target="_blank">Hacker News</a>
      <a href='/sitemap.xml' class="footer-link" target="_blank">SiteMap</a> 
    </div>
    <div class="footer-links">
      <a href="https://chartstudio.top/" target="_blank">chartstudio</a>
      <a href="https://wordart.top/" target="_blank">wordart</a>
      <a href="https://wordcloudmaster.com/" target="_blank">wordcloudmaster</a>
      <a href="https://pandaremit.top/" target="_blank">pandaremit</a>
      <a href="https://wordcloud.top/" target="_blank">wordclod</a>
      <a href="https://sankeymaster.com/" target="_blank">sankeymaster</a>
      <a href="https://pasteymaster.com/" target="_blank">pasteymaster</a>
      <a href="https://emperinter.cn/" target="_blank">emperinter</a>
      <a href="https://piechartmaster.com/" target="_blank">piechartmaster</a>		
      <a href="https://www.emperinter.info/" target="_blank">emperinter's blog</a>		
      <a href="https://emperinter.com/" target="_blank">emperinter</a>		
    </div>
  </footer>
  <Modal ref="modal">
    <template v-slot:default>
      <h2>👉 <a href='https://apps.apple.com/us/app/chartstudio-data-analysis/id6474099675' target='_blank'>ChartStudio - Data Analysis ！</a>👈</h2>
      <p><a href='https://apps.apple.com/us/app/chartstudio-data-analysis/id6474099675' target='_blank'>The ultimate chart-making tool ! support bar, line, area, column, polar bar, pie, rose, radar, beef distribution, organ, sunburst, sankey and more charts !</a></p>
    </template>
  </Modal>  
</template>

<script>
 /* eslint-disable */ 
import Modal from '@/components/Modal.vue'; // 引入 Modal 组件

export default {
  name: 'Footer',
  components: {
    Modal, // 注册 Modal 组件
  },
  data() {
    return {
      currentYear: new Date().getFullYear(), // Get the current year
    };
  }
};
</script>

<style scoped>
.footer {
  background-color: #343a40; /* Dark background color */
  color: white; /* Text color */
  text-align: center; /* Center text */
  padding: 20px; /* Padding for the footer */
  width: 100%;
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.footer p {
  margin: 8px 0; /* Remove default margin */
}

.footer-links {
  display: flex;
  flex-wrap: wrap; /* Allow links to wrap */
  justify-content: center; /* Center align links */
  margin: 10px 0; /* Margin for spacing */
  font-size: 18px; /* Adjust font size */
}

.footer a {
  text-decoration: none; /* Remove underline */
  color: white; /* Text color */
  margin: 5px 10px; /* Horizontal spacing between links */
  font-size: 18px; /* Adjust font size */
}

.footer a:hover {
  text-decoration: underline; /* Underline on hover */
  color:orange;
}

@media (max-width: 600px) {
  .footer a {
    font-size: 12px; /* Smaller font size on mobile */
  }
}
</style>